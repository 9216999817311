.required {
    color: red;
}

h2 {
    font-weight: 400;
}

.demo-editor {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-height: 150px;
    background-color: #fff;
}

.section-title {
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}

.section-title.basic {
    margin-bottom: 20px;
    margin-top: 20px;
}