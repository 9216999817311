#steps-container {
    margin: auto;
}

#steps {
    border-right: 1px solid #ddd;
    padding-right: 40px;
}

#step-editor {
    width: 90%;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
}

#step-editor div {
    width: 45%;
}

#step-editor div textarea {
    box-sizing: border-box;
}

#controls {
    display: flex;
    width: 75%;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 40px auto 0px auto;
}

.rendered-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.rendered-item span {
    margin-right: 10px;
}

.rendered-item i {
    cursor: pointer;
}

.ant-upload.ant-upload-select-picture-card {
    width: 30px !important;
    height: 30px !important;
    float: right;
    margin-right: 0px !important;
}

.ant-upload-list-picture-card-container {
    width: 50px !important;
    height: 30px !important;
}

.ant-upload.ant-upload-select-picture-card span div div {
    display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0px !important;
}

.rdw-image-imagewrapper img {
    max-width: 100%;
    height: auto; /* Mantén la proporción de la imagen */
}