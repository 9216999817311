.popup {
    position: absolute;
    top: calc(100% + 25px);
    left: -99px;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 9999;
}

.popup-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-container {
    position: relative;
    display: inline-block;
}