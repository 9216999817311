#steps-container {
    margin: auto;
}

#steps-container p {
    margin: 0;
}

#steps {
    border-right: 1px solid #ddd;
    padding-right: 40px;
}

#step-editor {
    width: 90%;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
}

#step-editor div {
    width: 45%;
}

#step-editor div textarea {
    box-sizing: border-box;
}

#controls {
    display: flex;
    width: 75%;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 40px auto 0px auto;
}

.rendered-item {
    margin-top: 10px;
    margin-bottom: 10px;
}

.rendered-item span {
    margin-right: 10px;
}

.rendered-item i {
    cursor: pointer;
}

.attachments-container {
    display: flex;
}

.clickable {
    cursor: pointer;
}

#steps-container {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Espaciado entre filas */
}

.step-row {
    display: flex;
    flex-direction: row;
    padding: 16px;
    border-radius: 8px;
}

.step-column {
    flex: 1;
    padding: 8px;
    overflow-wrap: break-word;
    word-break: break-word;
    height: fit-content;
}

.step-column h4 {
    margin: 0 0 8px;
    font-size: 16px;
    color: #333;
}


.step-expected-result {
    padding-left: 16px;
}

.editor-wrapper {
    max-width: 100%; /* Limita el contenedor al 100% del ancho disponible */
    overflow-x: hidden; /* Esconde contenido que desborda */
    box-sizing: border-box; /* Incluye el padding y border en el ancho total */
}


/* Asegúrate de que el contenido dentro del editor no exceda el ancho del contenedor */
.editor-content img {
    max-width: 100%; /* Las imágenes no serán más anchas que su contenedor */
    height: auto; /* Mantén la proporción de la imagen */
}

/* Opcional: Evitar desbordamiento horizontal del contenido */
.editor-content {
    overflow-wrap: break-word; /* Divide palabras largas */
    word-wrap: break-word; /* Compatibilidad con navegadores antiguos */
    white-space: normal; /* Asegúrate de que el texto no se quede en una sola línea */
}
