span.result-skip {
    color: gray;
}

span.result-failure {
    color: red;
}

span.result-success {
    color: green;
}

.demo-preconditions {
    overflow: hidden;
    height: inherit;
}