#test-cycle-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    width: 60%;
    margin: auto;
}

#test-cycle-wrapper div {
    width: 100%;
}

#test-cycle-wrapper h4 {
    width: 100%;
    text-align: left;
}

.clickable {
    cursor: pointer;
}