#wizard-stage-name {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    width: 60%;
    margin: auto;
}

#wizard-stage-name div {
    width: 100%;
}

#wizard-stage-name div textarea ,
#wizard-stage-name div input  {
    box-sizing: border-box;
    width: 100%
}
