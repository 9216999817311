.clickable {
    cursor: pointer !important;
}

.FolderTree .TreeNode {
    line-height: 1.75;
}

.folder-wrapper.wide .displayName {
    white-space: break-spaces;
}

.FolderTree {
    user-select: none;
}

.TreeNodeToolBar {
    display: block;
    margin-bottom: 10px;
}

.TreeNode[style*="margin-left: 30px;"] .TreeNodeToolBar {
    margin-left: 17px !important;
}

.TreeNode[style*="margin-left: 60px;"] .TreeNodeToolBar {
    margin-left: -1px !important;
}
  